import React, { useContext, useState } from 'react';
import { Text, StyleSheet, TouchableOpacity, View, Button } from 'react-native';
import { Class, User } from '../../../../models';
import { useNavigation } from '@react-navigation/native';
import { UserClassSession, UserState } from '../../../../types';
import { createUserCanceledClass, removeUserSessionJoined } from '../../../../services/goulapi/goulapi.user';
import { Mutex } from 'async-mutex';
import { dateDB_to_date, dayFormat } from '../../../../utils/dateUtils';
import { LoadingContext } from '../../../../components/LoadingContext';

const LIMIT_CHANGE_MINUTES = 90;

interface UserClassProps {
    setToReload: (toReload: boolean) => void;
    selectedClass: UserClassSession;
}

const UserClass: React.FC<UserClassProps> = ({ setToReload, selectedClass }) => {
    const navigator = useNavigation();
    const [isExpanded, setExpanded] = useState(false);
    const buttonBorderColor = selectedClass.classType.color || 'red';
    const { whoAmI, isAdmin  } = useContext(LoadingContext);

    const mutex = new Mutex();
    const today :Date = new Date();

    const handlePress = () => {
        setExpanded(!isExpanded);
    };

    async function cancelUserClass(session: UserClassSession) {
        await mutex.acquire();
        try {
            await createUserCanceledClass(whoAmI?.userName, session.classID, session.day);
            setExpanded(false);
        } finally {
            setToReload(true);
            mutex.release();
        }
    }

    async function cancelRescheduledUserClass(session: UserClassSession) {
        await mutex.acquire();
        try {
            await removeUserSessionJoined(session.joinedID);
            setExpanded(false);
        } finally {
            setToReload(true);
            mutex.release();
        }
    }
    
    function isCancellabled(date: string, time: string) {
        if(isAdmin) return true;
        const dateTime = new Date(`${date}T${time}`);
        dateTime.setMinutes(dateTime.getMinutes() - LIMIT_CHANGE_MINUTES);
        return today < dateTime;
    }

    const buttonModifyClass = (classSel, userID, setToReload) => {
        return (<>
            <Button title="Reagendar" onPress={() => {
                navigator.navigate('Cambiar fecha', { classSel: classSel, userID: userID, setToReload: setToReload });
            }
            } />
        </>);
    }

    const buttonCancelClass = (selectedClass) => {
        return (<>
            {isCancellabled(selectedClass.day, selectedClass.time) && (
                <Button title="Cancelar clase" color="#F87575" onPress={() => cancelUserClass(selectedClass)} /> //TODO add yes no modal
            )}
            
        </>);
    }

    
    const buttonCancelAndModifyClass = (classSel, userID, setToReload) => {
        return (<>
        {isCancellabled(selectedClass.day, selectedClass.time) && (
            <Button title= "Reagendar" onPress={() => {
                navigator.navigate('Cambiar fecha', { classSel: classSel, userID: userID, setToReload: setToReload });
            }
            } />
        )}
        </>);
    }

    const buttonCancelRescheduledClass = (selectedClass) => {
        return (<>
           {isCancellabled(selectedClass.day, selectedClass.time) && (
                <Button title="Cancelar clase reagendada" color="#F87575" onPress={() => cancelRescheduledUserClass(selectedClass)} /> //TODO add yes no modal
            )}
        </>);
    }

    const showCancelledClass = (rescheduledClass) => {
        const cancelledClass = rescheduledClass.cancelledClass;
        return (<>
            <Text style={styles.itemType}>
                {"Clase cancelada"}
            </Text>
            <Text style={styles.itemTime}>
                {dayFormat(dateDB_to_date(cancelledClass.date, cancelledClass.class.dayOfWeek))}
            </Text>
            <Text style={styles.itemTime}>
                {cancelledClass.class.time} - {cancelledClass.class.description}
            </Text>
        </>);
    }

    
    const showRescheduledClass = (rescheduledClass) => {
        const cancelledClass = rescheduledClass.joinedClass;
        return (<>
            <Text style={styles.itemType}>
                {"Clase agendada"}
            </Text>
            <Text style={styles.itemTime}>
                {dayFormat(dateDB_to_date(cancelledClass.date, cancelledClass.class.dayOfWeek))}
            </Text>
            <Text style={styles.itemTime}>
                {cancelledClass.class.time} - {cancelledClass.class.description}
            </Text>
        </>);
    }

    function renderExpandable_Regular() {
        return (
            <>
                {isExpanded && (
                    <View style={styles.divider} />
                )}
                {isExpanded && (
                    <>
                        <View style={styles.divider} />
                        <Text style={styles.itemTime}>{"______________________"}</Text>
                        {buttonCancelAndModifyClass(selectedClass, whoAmI?.userName, setToReload)}
                        {buttonCancelClass(selectedClass)}
                    </>
                )}</>
        )
    }

    function renderExpandable_Rescheduled() {
        return (
            <>
                {isExpanded && (
                    <View style={styles.divider} />
                )}
                {isExpanded && (
                    <>
                        <View style={styles.divider} />
                        <Text style={styles.itemTime}>{"______________________"}</Text>
                        {showCancelledClass(selectedClass)}
                        <View style={styles.divider} />
                        <Text style={styles.itemTime}>{"______________________"}</Text>
                        {buttonCancelRescheduledClass(selectedClass)}
                    </>
                )}</>
        )
    }

    function renderExpandable_Cancelled_Rescheduled() {
        return (
            <>
                {isExpanded && (
                    <View style={styles.divider} />
                )}
                {isExpanded && (
                    <>
                        <View style={styles.divider} />
                        <Text style={styles.itemTime}>{"______________________"}</Text>
                        {showRescheduledClass(selectedClass)}
                    </>
                )}</>
        )
    }

    function renderExpandable_Non_Rescheduled() {
        return (
            <>
                {isExpanded && (
                    <View style={styles.divider} />
                )}
                {isExpanded && (
                    <>
                        <View style={styles.divider} />
                        <Text style={styles.itemTime}>{"______________________"}</Text>
                        <Text style={styles.itemTime}>{"Esta clase no puede ser reagendada."}</Text>
                    </>
                )}</>
        )
    }

    function renderExpandable_Cancelled() {
        return (
            <>
                {isExpanded && (
                    <View style={styles.divider} />
                )}
                {isExpanded && (
                    <>
                        <View style={styles.divider} />
                        <Text style={styles.itemTime}>{"______________________"}</Text>
                        {buttonModifyClass(selectedClass, whoAmI?.userName, setToReload)}
                    </>
                )}</>
        )
    }


    return (
        <>
            <TouchableOpacity
                style={[styles.button, { borderColor: buttonBorderColor }]}
                onPress={handlePress}
            >
                <Text style={[
                    styles.itemType,
                    {
                        color:
                            selectedClass.state === null
                                ? 'black'
                                : selectedClass.state === UserState.RESCHEDULED
                                    ? 'lightblue'
                                    : selectedClass.state >= UserState.CANCELED_RESCHEDULED
                                        ? '#F9A97C' // Adjust color as needed for strikethrough
                                        : 'black', // Default color
                        textDecorationLine: selectedClass.state >= UserState.CANCELED_RESCHEDULED ? 'line-through' : 'none',
                    }]}>
                    {selectedClass.time} - {selectedClass.classType.name}
                </Text>
                <Text style={styles.itemDescription}>
                    {selectedClass.description}
                </Text>
                <Text style={styles.itemTime}>({selectedClass.duration}min)</Text>
                {selectedClass.state === UserState.REGULAR
                    ? renderExpandable_Regular()
                    : selectedClass.state === UserState.RESCHEDULED
                    ? renderExpandable_Rescheduled()
                    : selectedClass.state === UserState.CANCELED_RESCHEDULED
                    ? renderExpandable_Cancelled_Rescheduled()
                    : selectedClass.state === UserState.CANCELED_NON_RESCHEDULED
                    ? renderExpandable_Non_Rescheduled()
                    :renderExpandable_Cancelled()
                }
            </TouchableOpacity>
        </>
    );
};

export default UserClass;

const styles = StyleSheet.create({
    button: {
        margin: 10,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    itemType: {
        color: 'black',
        fontSize: 16,
    },
    itemTime: {
        color: 'lightgray',
        fontSize: 14,
    },
    itemDescription: {
        color: 'lightgray',
        fontSize: 10,
    },
    divider: {
        height: 1,
        backgroundColor: 'gray',
        marginVertical: 3,
    },
});
