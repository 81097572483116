import React, { useContext, useEffect, useState } from 'react'
import { Button, View, Text, StyleSheet, ActivityIndicator} from 'react-native';
import Constants from 'expo-constants'
import theme from '../theme'
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import AdminUserList from '../views/admin/users/AdminUserList'
import AdminClassSchedule from '../views/admin/schedule/AdminClassSchedule';
import AdminCalendarView from '../views/admin/calendar/AdminCalendarView';
import UserCalendarNavigator from '../views/user/userCalendar/UserCalendarNavigator'
import { useAuthenticator } from '@aws-amplify/ui-react-native'
import ClassTypeView from '../views/dev/classType/classTypeView';
import ReSendEmail from '../views/dev/reSendEmail/ReSendEmail';
import { LoadingContext } from './LoadingContext';

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.appBar.primary,
    flexDirection: 'row',
    paddingTop: Constants.statusBarHeight + 10
  },
  scroll: {
    paddingBottom: 15
  },
  text: {
    color: theme.appBar.textSecondary,
    paddingHorizontal: 10
  },
  active: {
    color: theme.appBar.textPrimary
  }
});

// Aquí está la función SignOut, pero ahora es un componente que muestra un botón
const LogoutScreen = () => {
  const { signOut } = useAuthenticator();

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>¿Estás seguro de que deseas cerrar sesión?</Text>
      <Button title="Cerrar sesión" onPress={signOut} />
    </View>
  );
};

const LoadingScreen = () => (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <ActivityIndicator size="large" />
    <Text>Loading...</Text>
  </View>
);
const Drawer = createDrawerNavigator();
const AppBar = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const isDev = user?.signInUserSession["idToken"]["payload"]["cognito:groups"]?.includes('dev');
  const { setWhoAmI, whoAmI, setIsAdmin, isAdmin  } = useContext(LoadingContext);
  
  const [toReload, setToReload] = useState(true);

  useEffect(() => {
    const initWhoAmI = async () => {
      if (!whoAmI.userName) { // Check if whoAmI is uninitialized
        setWhoAmI({ 
          userName: user.username, 
          email: user.attributes.email, 
          name: user.attributes.name 
        });
        setIsAdmin(user?.signInUserSession["idToken"]["payload"]["cognito:groups"]?.includes('admin'));
      }
      setToReload(false); // Always set toReload to false once initialized
    };

    if (toReload) {
      initWhoAmI();
    }
  }, [toReload, setWhoAmI, whoAmI, setIsAdmin, isAdmin, user]);

  const getDrawerScreens = () => {
    const screens = [];

    if (isAdmin) {
      screens.push(
        <Drawer.Screen key="Calendario" name="Calendario" component={AdminCalendarView} />,
        <Drawer.Screen key="Usuarios" name="Usuarios" component={AdminUserList} />,
        <Drawer.Screen key="Clases" name="Clases" component={AdminClassSchedule} />
      );
    } else {
      screens.push(
        <Drawer.Screen key="Mis Clases" name="Mis Clases" component={UserCalendarNavigator} />
      );
    }

    if (isDev) {
      screens.push(
        <Drawer.Screen key="ReSend Email" name="ReSend Email" component={ReSendEmail} />,
        <Drawer.Screen key="Create class Type" name="Create class Type" component={ClassTypeView} />
      );
    }

    screens.push(
      <Drawer.Screen key="Logout" name="Logout" component={LogoutScreen} />
    );

    return screens;
  };
  
  return (
    <NavigationContainer>
      <Drawer.Navigator>
        {/* Ensure there's at least one fallback screen */}
        {toReload ? (
          <Drawer.Screen 
            key="Loading" 
            name="Loading" 
            component={() => <LoadingScreen />} // Replace with your loading UI
          />
        ) : (
          getDrawerScreens()
        )}
      </Drawer.Navigator>
    </NavigationContainer>
  );
};

export default AppBar