import React, { useEffect, useState } from 'react';
import { CalendarList, LocaleConfig } from 'react-native-calendars';
import { View, Text, TouchableOpacity, StyleSheet, TouchableWithoutFeedback, Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';;
import { ScrollView } from 'react-native-gesture-handler';
import { useAuthenticator } from '@aws-amplify/ui-react-native';
import { useContext } from 'react';
import { Class } from '../../../models';
import { getClassesFromUser } from '../../../services/goulapi/goulapi.service';
import { setClassSessionToAgendaFormat, setClassSessionToCalendarFormat } from '../../../utils/agendaUtils';
import { dayFormat } from '../../../utils/dateUtils';
import { LoadingContext } from '../../../components/LoadingContext';
import UserClass from './component/UserClass';

LocaleConfig.defaultLocale = 'es';
LocaleConfig.locales['es'] = {
    monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ],
    monthNamesShort: ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'],
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom.', 'Lun.', 'Mar.', 'Mié.', 'Jue.', 'Vie.', 'Sáb.'],
    today: 'Hoy'
};

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        overflow: 'hidden',
    },
    calendarContainer: {
        flex: 1,
        zIndex: 0,
    },
    sidebarContainer: {
        width: windowWidth >= 600 ? '25%' : '100%',
        backgroundColor: 'white',
        padding: 20,
        borderLeftWidth: 1,
        borderColor: '#e0e0e0',
        zIndex: 1,
    },
    closeButton: {
        position: 'absolute',
        top: 5,
        right: 10,
        padding: 10,
        borderRadius: 5,
        zIndex: 2,
    },
    closeIcon: {
        color: '#CACBCA', // Greyish color
        fontSize: 30,
    },
    shadow: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 0,
    },
    calendarTheme: {
        backgroundColor: '#ffffff',
        calendarBackground: '#ffffff',
        textSectionTitleColor: '#b6c1cd',
        textSectionTitleDisabledColor: '#d9e1e8',
        selectedDayBackgroundColor: '#EC2811',
        selectedDayTextColor: '#ffffff',
        todayTextColor: 'red',
        dayTextColor: '#2d4150',
        textDisabledColor: '#d9e1e8',
        monthTextColor: '#4DDE4D',
        indicatorColor: 'red',
        textDayFontWeight: '300',
        textMonthFontWeight: 'bold',
        textDayHeaderFontWeight: '300',
        textDayFontSize: 16,
        textMonthFontSize: 15,
        textDayHeaderFontSize: 16,
    },
    dayHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start', // Updated to justify to the right
        marginBottom: 10,
    },
    daysubHeader: {
        flexDirection: 'column',
        justifyContent: 'flex-start', // Updated to justify to the right
        marginBottom: 10,
    },
    dayNumber: {
        width: 50, // Updated to make the circle slightly larger
        height: 50, // Updated to make the circle slightly larger
        borderRadius: 25, // Updated to make the circle slightly larger
        backgroundColor: '#4DDE4D', // You can set the desired color
        marginLeft: 10, // Updated to create space between the number and text
        alignItems: 'center',
        justifyContent: 'center',
    },
    dayText: {
        fontWeight: 'bold',
        fontSize: 20, // Updated to make the number bigger
        color: 'white', // Updated to set the font color to white
    },
    dayNameText: {
        fontSize: 22, // Updated to make the number bigger
        marginLeft: 7,
        textTransform: 'capitalize', // Added to capitalize the first letter
    },
    monthNameText: {
        fontSize: 14, // Updated to make the number bigger
        color: 'lightgrey',
        marginLeft: 7,
        textTransform: 'capitalize', // Added to capitalize the first letter
    },
});

const FUTURE_MONTHS_VIEW = 1;
const PAST_MONTHS_VIEW = 1;
function UserCalendarView() {
    const [selectedDay, setSelectedDay] = useState('');
    const today = new Date();
    const [selectedDayClasses, setSelectedDayClasses] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [toReload, setToReload] = useState(true);
    const { isLoading, setIsLoading, whoAmI } = useContext(LoadingContext);

    const daySelStart = new Date(today);
    daySelStart.setDate(1);
    daySelStart.setMonth(daySelStart.getMonth() - PAST_MONTHS_VIEW);

    const daySelEnd = new Date(today);
    daySelEnd.setDate(1);
    daySelEnd.setMonth(daySelEnd.getMonth() + FUTURE_MONTHS_VIEW + 1, daySelEnd.getDate() - 1);

    const [availableClasses, setAvailableClasses] = useState({})
    const [markedClasses, setMarkedClasses] = useState({})

    useEffect(() => {
        async function setAllClasses() {
            if (toReload) {
                setIsLoading(true);
                const classesToAgendaFormat = await setClassSessionToAgendaFormat(daySelStart, daySelEnd, await getClassesFromUser(whoAmI?.userName, daySelStart, daySelEnd));
                setAvailableClasses(classesToAgendaFormat);
                setMarkedClasses(setClassSessionToCalendarFormat(dayFormat(new Date()), classesToAgendaFormat));
                setSidebarVisible(false);
                setToReload(false);
                setIsLoading(false);
            }
        }
        setAllClasses();
    }, [toReload]);

    useEffect(() => {
        async function getDailyValue() {
            if (!toReload && !isLoading) {
                setIsLoading(true);
                if(selectedDay != '')
                {
                    var currentDate = dayFormat(new Date(selectedDay));
                    setSelectedDayClasses(availableClasses[currentDate]);
                    setMarkedClasses(setClassSessionToCalendarFormat(currentDate, availableClasses));
                }
                setIsLoading(false);
            }
        }
        getDailyValue();
    }, [selectedDay && sidebarVisible, isLoading]);

    const handleDayPress = (day) => {
        setSelectedDay(day.dateString);
        setSidebarVisible(true);
    };

    const closeSidebar = () => {
        setSelectedDay('');
        setSidebarVisible(false);
    };

    const renderDayHeader = () => {
        if (!selectedDay || !sidebarVisible) {
            return null;
        }
    
        const selectedDate = new Date(selectedDay);
        const dayNumber = selectedDate.getDate();
        const dayName = new Intl.DateTimeFormat('es', { weekday: 'long' }).format(selectedDate);
        const month = new Intl.DateTimeFormat('es', { month: 'long' }).format(selectedDate);
        
        return (
            <View style={styles.dayHeader}>
                <View style={styles.dayNumber}>
                    <Text style={styles.dayText}>{dayNumber}</Text>
                </View>
                <View style={styles.daysubHeader}>
                <Text style={styles.monthNameText}>{month}</Text>
                <Text style={styles.dayNameText}>{dayName}</Text>
                </View>
            </View>
        );
    };    

    const renderSidebar = () => {
        if (!selectedDay || !sidebarVisible) {
            return null;
        }

        return (
            <View style={styles.sidebarContainer}>
                <TouchableOpacity style={styles.closeButton} onPress={closeSidebar}>
                    <Ionicons style={styles.closeIcon} name="md-close-outline" />
                </TouchableOpacity>
                {renderDayHeader()}
                {isLoading ? null :
                    <ScrollView>
                        {selectedDayClasses.sort((a, b) => a.time.localeCompare(b.time)).map((selClass: Class, key) => (
                                <UserClass setToReload = {setToReload} key={key} selectedClass={selClass}></UserClass>
                        ))}
                    </ScrollView>}
            </View>
        );
    };

    const renderShadow = () => {
        if (!sidebarVisible) {
            return null;
        }

        return (
            <TouchableWithoutFeedback onPress={closeSidebar}>
                <View style={styles.shadow} />
            </TouchableWithoutFeedback>
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.calendarContainer}>
                <CalendarList
                    theme={styles.calendarTheme}
                    firstDay={1} //Start on Modnay
                    current={dayFormat(daySelStart)}
                    minDate={dayFormat(daySelStart)}
                    maxDate={dayFormat(daySelEnd)}
                    pastScrollRange={0}
                    futureScrollRange={1 + FUTURE_MONTHS_VIEW}
                    markedDates={markedClasses}
                    onDayPress={handleDayPress}
                />
            </View>
            {renderSidebar()}
            {renderShadow()}
        </View>
    );
}

export default UserCalendarView;